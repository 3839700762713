
.header {
  position: relative;
  background: linear-gradient(
      rgba(191, 0, 194, 0.70), 
      rgba(191, 0, 194, 0.70)
    ),
    url('../../assets/images/LandingPage/blog.png') no-repeat center center;
  background-size: cover;
  background-position: center top ;
  color: white;
  height: 500px;
display: flex;
justify-content: center;
align-items: center;
}



.header-content {
  text-align: center;
  margin: auto;
}

/* Apply text-align right for RTL */
.header-contentRtl {
  text-align: center;
  margin: auto;
}

.header h1 {
  color: #EEE;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: auto;
}
.header p{
  color: #EEEEEE;
  font-size: 15px;
  font-weight: 700;
  line-height: 22.5px;
  width: fit-content;
  width: -moz-fit-content;
  padding: 5px 10px;
  margin-bottom: 15px;
  max-width: 100%;
  border-radius: 8px;
  background: rgba(43, 42, 41, 0.15);
  text-align: center;
  margin: auto;
}


.header .subtitle {
  text-align: center;
  margin: auto;
  
}

.header .subtitle2 {
  color: #E5E5E5;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 490px;
  max-width: 100%;
  background: transparent;
  padding: 0;
  text-align: center;
  margin: auto;
}
.header-contentRtl h1{
  margin-top: .5rem;
  margin-bottom: .5rem;
}
/* .header  .subtitle2 {
  color: #E5E5E5;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
} */

.popular-topics {
  color: white;
  padding: 20px 0;
  margin: 80px 0 80px 0;
  margin: auto;
}

.popular-topics h2 {
  color: #EEE;
 
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 25px;
  text-align: left;
  margin-bottom: 1.5rem;
}

.popular-topics .category-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */

  margin-left: 30px;
  margin-bottom: 20px;
}

.popular-topics .category-buttons p {
  cursor: pointer;
  margin: 0 10px; /* Adjust margin for smaller screens */
  font-size: 18px; /*14 Adjust font size for smaller screens */
  font-weight: 600; /*500*/
  line-height: 25px;
}

.category-buttons  :active{
  color:  #BF00C2 !important;

}
.category-buttons p:hover{
  color:  #BF00C2 !important;

}



.popular-topics .posts {
  display: flex;
    flex-wrap: wrap;
  margin: 10px;

  
}

.post-card {
  background-color: transparent  !important;
  margin: 10px;
  box-shadow: none;
  color: #444;
  overflow: hidden; 
  cursor: pointer;

}


.post-card .cardImage {
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: 100% 100%;
  background: lightgray center / 100% 100% no-repeat;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth effect */


}

.post-card:hover .cardImage {
  transform: scale(1.09); /* Scale the image on hover */
}

.post-card .post-content {
margin: 10px 0;
padding: 0;
text-align: left;

}

.post-date {
  color: #EEE;
letter-spacing: 2px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

 .post-title {
  color: #EEE;
 
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
}

.post-summary{
  color: #EEE;

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */


}


.imageContainer {
  position: relative;
  overflow: hidden; /* Ensure the image doesn't overflow the container */

}

.imagePlaceholder {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #aaa;
  font-size: 1.2rem;
}

.categoryBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background-color: #5c5c5c !important;
}


.panner{
  background: linear-gradient(
    rgba(191, 0, 194, 0.70), 
    
    rgba(191, 0, 194, 0.70)
  ),
  url('../../assets/images/LandingPage/blog2.png') no-repeat center center;
background-size: cover;

height: 500px;
display: flex;
align-items: center;
justify-content: center;
width: 100% !important;

}

.panner-content {
  text-align: center;
}

.panner-content h1 {
  color: #EEE;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 930px;
  max-width: 100%;

  margin: auto;
  text-align: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.panner-content p{
 
  color: #EEE;
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
margin: auto;
width: fit-content;
width: -moz-fit-content;
padding: 5px 10px;
max-width: 100%;
border-radius: 8px;
background: rgba(43, 42, 41, 0.15);
}
.panner-content .subtitl2{
  color: #E5E5E5;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 490px;
  max-width: 100%;
  background: transparent;
  text-align: center;
  padding: 0;
}

.second-posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0 ;
 
  
}



.second-post-card {
  position: relative;
  margin: 10px;
  overflow: hidden;
  background: lightgray center / 100% 100% no-repeat;
  cursor: pointer;
  box-shadow: none;
  color: #444;
  width: 100%;
  max-width: 290px; /* Adjust this value as per your design */
}

.second-post-card .cardImage2 {

  width: 100%;
  height: 310px;
  cursor: pointer;
  background-size: 100% 100%;

}

.second-post-content {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 100%;
background: linear-gradient(180deg, rgba(29, 29, 28, 0) 0%, #2B2A29 100%);
  color: #EEE;
  display: flex;
  flex-direction: column;
  justify-content: end; 
  text-align: left; 
  padding: 10px;
}



.second-post-card:hover .second-post-content {
  bottom: 0;
}

.second-post-title {
  font-size: 1rem;
  margin: 10px 0;
}

.second-post-content .post-date {
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  color: #ccc;
}


.imageContainer2 {
  position: relative;
}
.categoryBadge2 {
  position: absolute;
  top: 10px;
  right: 1%;
  color: white;
  background-color: #5c5c5c !important;
}

.light .No-Posts{
  color: #2B2A29;
  text-align: center !important;
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 600;


}

.dark .No-Posts{
  color: #EEE;
  text-align: center !important;
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 600;


}

@media (min-width: 290px) and (max-width: 1080px) {
  .categoryBadge2 {
    position: absolute;
    top: 10px;
    right: 2%;

  }
  
  }

  
@media (min-width: 290px) and (max-width: 780px) {
  .header {
    position: relative;
    background: linear-gradient(
        rgba(191, 0, 194, 0.70), 
        rgba(191, 0, 194, 0.70)
      ),
      url('../../assets/images/LandingPage/blog.png') no-repeat center center;
    background-size: cover;

    color: white;
  
  }
  .second-post-card {
    width: 100%; /* Adjusted to take full width on smaller screens */
  }
  .header-content{
 max-width: 100%;


  }
  .header h1{
    font-size: 17px;
    line-height: 1.5rem;
    font-size: 17px;
max-width:96%;
  }
 
  .header  .subtitle2 {
    color: #E5E5E5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    width: 89%;
  }
  .header p{
    color: #E5E5E5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
 
  }




  .panner-content h1{
    font-size: 17px;
    line-height: 1.7rem;
    width: 100%;
    max-width: 100%;
   
  }
  .panner-content p{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
   
  }

  .panner-content .subtitl2{
    width: 89%;
    font-size: 16px;
  }
  .popular-topics .category-buttons p {
    margin: 5px; 
    font-size: 13px; 
    font-weight: 700;
  }

  .popular-topics.rtl .category-buttons p {
    margin: 0 5px 0 5px !important;
  }

  .light .No-Posts{
    color: #2B2A29;
    text-align: center !important;
    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 600;
  
  
  }
  
  .dark .No-Posts{
    color: #EEE;
    text-align: center !important;
    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 600;
  
  
  }

  }
  
  
  .light.popular-topics h2 {
    color: #2B2A29;
  }
  .light.post-content ,.light .post-date ,.light .post-summary ,.light .post-title {
    color: #2B2A29;
  }

  
  .light.second-post-content *{
    color: #EEE;
  }

  .light.post-title ,.light.post-summary {
    color: #2B2A29;

  }
.light.category-buttons p{
  color: #2B2A29;
}


  .dark.popular-topics h2 {
    color: #EEE;
  }
  .dark.post-content .post-date ,.post-summary ,.post-title {
    color: #EEE;
  }

  .dark.post-title ,.dark.post-summary{
    color: #EEE;

  }

  .error{
    background-color: rgb(169, 23, 23);
    color: #EEE;
    font-size: 20px;
    text-align: center;
  }



  
.dark.all{
  background-color: #2B2A29;
color: #EEE;
}

.light.all{
  background-color: #EEE;
  color: #2B2A29;
 
}





/* .rtl.popular-topics *{
  text-align: right;
} */
.popular-topics.rtl *{
  text-align: right;
  direction: rtl;
}

.popular-topics.rtl h2 {
  margin-right: 25px;
  text-align: right;
}

.popular-topics.rtl .category-buttons {
  margin-right: 30px;
}

.popular-topics.rtl .category-buttons p {
  margin: 0 17px 0 17px;
}

.popular-topics.rtl .category-buttons p:hover,
.popular-topics.rtl .category-buttons p:active {
  color: #BF00C2 !important;
}


.rtlSecond *{
  text-align: right;
  direction: rtl;
    
  }


  .rtlforenglish {
    direction: rtl;
    text-align: right !important;
  }

  .rtlfordate {
    direction: rtl;
    text-align: right !important;
  }


  

  