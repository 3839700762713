.Footer{
    background-color: #BF00C2;
padding: 3rem 3rem 6rem 3rem;

}
.Footer a{
    color: #E5E5E5;

}
.Footer p{
    color: #E5E5E5;

}
.Footer h6{
    color: #FFF;

font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 25px; 
}

.Frtl p  {
      font-size: 19px;

    text-align: right !important;
}

.Frtl a  {
    font-size: 19px;
    text-align: right !important;
}
.Frtl h6  {
  font-size: 25px;
    text-align: right !important;
}

.HeadquarterPlac{
    width: 70%;
max-width: 100%;
font-size: 12px;
}

.Frtl .HeadquarterPlac{
    width: 100%;
    max-width: 100%;
    font-size: 17px;
}



@media (min-width: 250px) and (max-width: 580px) {
  .Footer{
    padding: 30px !important ;
  }
    .custom-cols {
      flex-basis: 50%!important;
      max-width: 50%!important;
    }
    .Footer p{
        font-size: 12px;
    }
    .Footer h6{
        font-size: 15px;
    }
    .Footer a{
      font-size: 12px;
    }
  

    
  }


  .popupParagraph {
    padding: 0 3rem;
     margin: auto;
     margin-bottom: 15px;
     font-size: 20px;
     font-weight: 500;
     line-height: 30px;
     text-align: center;
     color: #2B2A29;
   }
   
   .popupIconBody {
    width: 193.333px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: auto;
margin-bottom: 50px;


}

.popupIcon {
  width: 40px;
  height: 40px;
  background-color: #BF00C2;
  border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
}

.popupIcon img {
  width: 25px;
  height: 25px;

}

.popupIcon a {
  color: #FFFFFF !important;
  text-decoration: none;
}
