
.dark.all{
  background-color: #2B2A29;
}
.dark.light{
  background-color: #EEE;
}
.searchContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}


.JoinUs {
  background: linear-gradient(rgba(43, 42, 41, 0.70), rgba(43, 42, 41, 0.70)),
    url('../../assets/images/LandingPage/f97a758bdaced695e2d07f2b8d386a76.png');
  background-size: cover;
  background-position: center 30px;
  background-repeat: no-repeat;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;

}

.contain{
padding: 50px;
text-align: left;

}

.row {
  gap: 60px;
} 
.Row{
  display: flex;
  flex-direction: row-reverse; 


}






.content {

  gap: 35px;
  max-width: 100%;
  margin-top: 14%;
}

.content h1 {
  color: #EEE;
  font-size: 58px;
  font-style: normal;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: 0.2px;
  width: 579px;
  max-width: 100%;
 
}
.contentRtl{
 
  direction: rtl; 
  text-align: right;
 
}
.containRtl{
  text-align: right;

}
.contentRtl h1{
 font-size: 55px; 
 max-width: 100%;
 width: 100%;
 margin-bottom: 20px;


}


.content p {
  color: #EEE;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.2px;
  width: 580px;
  max-width: 100%;
}

.contentRtl p{
  font-size: 25px; 
 max-width: 100%;
 width: 95%;
 }

.formContainer {

  padding: 40px;
  border-radius: 10px;
  max-width: 100%;
  
  width: 330px;
  margin-top: .7rem;
}
.dark .formContainer {
  background: #2B2A29;
}

.light .formContainer {
  background: #EEE;
 
}


.form {
  width: 100%;
}



.formRtl{
  direction: rtl;
}


.formRtl label{
  margin-right: 7px;
}

label {

  margin-bottom: 10px;
  margin-left: 7px;
}
.dark label {
  color: #eee;
}

.light label {
  color: #2B2A29;
}

.formHeading {
 
  text-align: center;

  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0.1px;
  margin: 0px 0 40px 0;

}
.dark .formHeading {
  color: #eee;
}

.light .formHeading {
  color: #2B2A29;
}


.formControl,
.MDBInput,
.dropdownToggle {
  display: flex;
  width: 100%; 
  height: 50px;
  padding: 11px 29.008px 11px 18px;
  align-items: center;
  gap: 34.992px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #BF00C2 !important;
  background: transparent;
  box-shadow: none !important;
  margin-bottom: 1.5rem;
  color: #e809ec;
  font-weight: 600;
}

.dropdownToggle {
  max-width: 100%;
  text-align: left;
  color: #737373;
  border-radius: 5px;
  background-color: transparent;
  position: relative;
  cursor: pointer; 
}



.dropdownToggle:hover {
  color: #2B2A29 !important;
}

.dropdownToggle:hover::after {
  content: "\f107"; /* FontAwesome icon for angle-down */

  color: #2B2A29 !important;

}


.dropdownToggle::after {
  content: "\f107"; /* FontAwesome icon for angle-down */
  font-family: "Font Awesome 5 Free";
  color:  #737373 !important;
  font-weight: 900;
  position: absolute;
  right: 20px; /* Adjust this value to position the arrow properly */
  font-size: 15px;
}

.dropdownRTL .dropdownToggle::after{
  content: "\f107"; /* FontAwesome icon for angle-down */
  font-family: "Font Awesome 5 Free";
  color:  #737373 !important;
  font-weight: 900;
  position: absolute;
  left: 20px; /* Adjust this value to position the arrow properly */
  font-size: 15px;
}

.dropdownRTL .dropdownToggle:hover {
  color: #2B2A29 !important;
}

.dropdownRTL .dropdownToggle:hover::after {
  content: "\f107"; /* FontAwesome icon for angle-down */

  color: #2B2A29 !important;

}

::placeholder {
  color: #737373 !important;

}
.formRtl ::placeholder{
  direction: rtl !important;
text-align: right;
}


.mb4 {
  margin-bottom: 1.5rem;
}


.dropdown {
  position: relative; 
}

.dropdownMenu {
  max-height: 300px;
  overflow-y: scroll;
  overflow-y: auto;
  border: 2px solid #BF00C2;
  color: #EEE  !important;
  position: absolute; 
  border-radius: 5px 5px ;
border: 1px solid  #BF00C2;
  top: 100%; 
  left: 0; 
  width: 100%; 
  z-index: 100; 

}

.dark .dropdownMenu {
  background: #2B2A29;
}
.light .dropdownMenu {
  background: #eee;
}


.dropdownItem  {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
  letter-spacing: 0.2px;
  padding: 11px 131px 11px 18px;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid  #BF00C2;
}

.dark .dropdownItem { 
  color: #EEE;
}
.light .dropdownItem {
  color: #2B2A29;
}

.dropdownItem:hover {
  background-color: #BF00C2 !important;
}

.countryItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

  padding: 11px 17px 11px 18px;
}

.dark .countryItem { 
  color: #EEE;
}
.light .countryItem {
  color: #2B2A29;
}

.countryItem:hover {
  background-color: #BF00C2 !important;
}


.countryItem img {
  width: 20px;
  height: auto;
  margin-right: 20px;
  margin-left: 10px;
}


.countryItemRTL,
.dropdownItemRTL {
  font-size: 17px;
  font-weight: 600;
  text-align: right;
  font-size: 17px;
  padding: 11px 17px 11px 18px;
}

.countryItemRTL .countryFlag {
  margin: 0 0 0 20px;
}


.dropdownToggle::after {
  margin-left: auto;
  font-size: 17px;
}

.dark .dropdownToggle::after {
  color: #eee;
}
.light .dropdownToggle::after {
  color: #2B2A29;
}

.dropdownToggle:hover {
  background-color: #BF00C2 !important;
}


.JoinUsBTN {
  padding: 15px 40px;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background: #BF00C2 !important;
  border: none !important;
  margin-top: 20px;
  color: #EEE;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin-bottom: 10px;
}


@media (max-width: 1221px) {
  .flex {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .content {
   width: 100%;
   margin: auto;
   text-align: center;
  }

  .formContainer {
    margin-top: 20px;
    margin: auto;
  }

  .JoinUs {
    padding-top: 10px; 
    padding-bottom: 60px; 
  }

  .content h1{
    font-size: 49px;
    width: 100%;
    margin-top: 10px;
  
  
  }
  .content p{
    width: 100%;
    margin: auto;
    font-size: 25px;
  
  }

}

@media (max-width: 768px) {
  .flex {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .content {
max-width: 100%;
width: 100%;
text-align: center;
  }

  .formContainer {
    margin-top: 10x;
    margin: auto;
  }

  .JoinUs {
    padding-top: 10px; 
    padding-bottom: 60px; 
  }

  .content h1 {
    font-size: 45px;
    line-height: 60px;
    margin: auto;
  }

  .content p {
    font-size: 22px;
    line-height: 28px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }
}

@media (max-width: 500px) {



  .formContainer {
    margin-top: 5rem;
    width: 360px !important;
    max-width: 100%;
    padding: 20px; 
    margin-bottom: 20px;
    order: 1;
  }

  .formHeading {
    font-size: 20px;
  }
  .contain{
    padding: 11px;
  }
  .content{
    text-align: center;
    width: 100%;
    order: 2;
  }
  .content h1 {
    font-size: 40px;
    line-height: 48px;
    margin: auto;
    max-width: 90%;
  }

  .content p {
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
  }
}




.alert {
  padding: 15px;
  margin-top: 10px !important;
  border-radius: 4px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  margin-top: 2rem;


}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
}

.errorMessage{
  color: #a94442;
  margin-top: -1rem !important;
  margin-bottom: 1rem;
  margin-left: 6px !important;
  margin-right: 5px;
}

.alertCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 650px !important;
  max-width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
