.carTop {
    width: 85px;
    position: fixed;
    bottom: -180px;
    right: 0px;
    z-index: 999;
    opacity: 1;
    cursor: pointer;
    transition: bottom 1.0s;
}

.carTop.show {
    bottom: 5% !important;
    z-index: 999999999;
    transition: bottom 1.0s;
}

.carTop.running {
    bottom: 100% !important;
    transition: bottom 1.0s;
}

.carTop.hidden {
    display: none;
}

.carTop::before,
.carTop::after {
    content: "";
    position: absolute;
    transition: opacity 0.2s;
    opacity: 0;
}

.carTop:hover::before,
.carTop:hover::after {
    opacity: 1;
}

.carTop::before {
    top: -15px;
    left: 0px;
    width: 85px;
    height: 89px;
    background: url(../../assets/images/LandingPage/car-focus.png) no-repeat 0 0;
}

@media (max-width: 767px) {
    .carTop.show {
        bottom: 3% !important;
    }
    .carTop::before {
        top: -15px;
        left: 0px;
        width: 85px;
        height: 89px;
        background: url(../../assets/images/LandingPage/car-focus.png) no-repeat 0 0;
    }
    .carTop {
        width: 55px;
        right: 15px;
    }
}
