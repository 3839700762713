  


   body {
    /* font-family: 'Poppins', sans-serif; */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

body.light {
  background-color: #EEE;
  color: #2B2A29;
}

body.light h1, body.light h2, body.light h3, body.light h4, body.light h5 {
  color: #2B2A29;
}


body.light p {
  color: #2B2A29;
}



body.dark {
  background-color: #2B2A29;
  color: #EEE;
}

body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5 {
  color: #FFF;
}

body.dark p {
  color: #EEE;
}

  
  
  .dark.all{
    background-color: #2B2A29;
  }
  .dark.light{
    background-color: #EEE;
  }


  .home {
    width: 100%;
    height: 900px;
    background: linear-gradient(rgba(43, 42, 41, 0.70), rgba(43, 42, 41, 0.70)),
    url('../../assets/images/LandingPage/f97a758bdaced695e2d07f2b8d386a76.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  


  

 
  .contnet {
text-align: center;
  }
  
  .contnet h1 {
    color: #FFF;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .contnet p {
    width: 390px;
    margin: auto;
    color: #EEE;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  
  .dwonloadBTN {
    width: 220px;
    height: 77px;
    border-radius: 50px;
    background: #BF00C2;
    box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.50);
  
    color: #FFF;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding: 25px 40px;
margin-top: 45px;
  transition: background-color 0.3s, transform 0.3s;
   
  }

 
  
  .dwonloadBTN:hover, .dwonloadBTN2:hover {
    background-color: #9f00a2;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  }
  .dwonloadBTN:focus ,.dwonloadBTN2:hover{
    background-color: #9f00a2 !important;
  }
  
  .services{
margin: 80px 0 0 0;
  }
  .serviceCol{
    height: auto;
    margin-top: 80px;

  }
  .services h1 {
    font-size: 50px;
    font-weight: 600;
    line-height: 75px;
    margin: auto;
    text-align: center;
    color: #FFF;
position: relative;
  }

  .vectorImage {
  
    display: none; 
  }
 
  
  .services h5 {
    color: #EEE;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 45px;
  }
  
  .services p {
    width: 282px;
  
    margin: auto;
    margin-bottom: 20px;
    color: #EEE;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  
  .IconBody {
    width: 113px;
    height: 113px;
    background-color: #BF00C2;
    border-radius: 50%;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .IconBody img{
    width: 50px !important;
    height: 50px !important;
  }
 
  
  .downloadSection {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
  margin-top: 85px;
  margin-bottom: 60px;
  
  }
  .downloadContent{
    width: 928px;
    margin: auto;
    text-align: center;
  }
  .downloadSection p {
 
    color: #EEE;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
  
    margin-top: 40px;
  }
  

  
  .IconBody img {
    width: 70px;
    height: 70px;
    margin: auto;
  }
  
  .app {
  
    background-image: url('../../assets/images/LandingPage/planet2.png');
    background-repeat: no-repeat;
    background-size: 116% 111%; 
    background-position: 52% ; 
    width: 100%;
    height: 83vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 115px 0px 100px 0px; 

  }
  



  
  .appContent {

display: flex;
align-items: center;
justify-content: center;
 
  }
  
  .app h1 {
    color: #EEE;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 60px 0;
  }
  
  .storeButtons {
    width: 650px;
height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin: auto;
  }
  
  
  .storeButton {
    cursor: pointer;
    width: 294px !important;
    height: 84px !important;
  }
  
  .popupParagraph {
    margin: auto;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #2B2A29;
    margin-bottom: 20px;
  }

    .popupIconBody {
      width: 193.333px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin: auto;
margin-bottom: 50px;


  }
  
  .popupIcon {
    width: 40px;
    height: 40px;
    background-color: #BF00C2;
    border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  }
  
  .popupIcon img {
    width: 25px;
    height: 25px;
  
  }
  
  .popupIcon a {
    color: #FFFFFF !important;
    text-decoration: none;
  }
  


  .light {
    background-color: #EEE;

  }
  
  .dark {
    background-color: #2B2A29;
    
  }
  
  .light.services h1, .light.services h5, .light.services p {
    color: #2B2A29;
  }
  


  .light.downloadSection p   {
    color: #2B2A29;
  }
  .light.app h1{
    color: #FFF;
  }
  
  
 
  
  .dark.services h1, .dark.services h5, .dark.services p  {
    color: #EEE;
  }


  
  @media (max-width: 1200px) and (min-width: 992px) {
 
    .home{
         
      background-size: cover;
      background-position: center ;
         }
      .contnet h1 {
        font-size: 40px;
        
      }
      .contnet p {
        max-width: 60%;
        font-size: 22px;
        line-height: 22px;
      }

  
    .appContent {
      width: 90%;
    }
  
    .app h1 {
      font-size: 35px;
      line-height: 50px;
    }
  
    .storeButton {
      width: 200px;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
     .home{
         
 background-size: cover;
 background-position: center ;
    }

    .storeButtons {
      width: 90% !important;
    }
    .storeButton {
      width: 180px;
    }



    .contnet h1 {
      font-size: 33px;
      line-height: 40px;
      max-width: 100%;
    }
  
    .contnet p {
      max-width: 70%;
      font-size: 20px;
      line-height: 25px;
    }
 
.appContent{
  width: 90%;
  max-width: 100%;

 }
 
   .app h1{

     color: #EEE;
 text-align: center;
 font-size: 30px;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
 margin: auto;
 margin-bottom: 30px;
 margin-top: 0;
   }
   .storeButtons {
     width: auto;
     height: auto;
  
     gap: 20px; 
     
   }
 
   .storeButton {
     width: auto !important; 
     height: 60px !important; 

    }
  
  }
  
  @media (max-width: 767px) and (min-width: 576px) {
    .home{
      background-position: center;
      background-size: cover;
       }
   .appHeading{
    font-size: 30px !important;
   }
    .storeButtons{
      width: 100% !important;
      flex-direction: column-reverse;
      gap: 15px;
      }

    .storeButton{
      width: 60% !important; 

      }
   
  
    .contnet h1 {
      font-size: 37px;
      line-height: 35px;
    }
  
    .contnet p {
      max-width: 55%;
      margin-top: 1rem;
      font-size: 21px;
      line-height: 27px;
    }
    .services h1 {
      font-size: 45px;
      font-weight: 600;
      line-height: 75px;
      margin: auto;
      text-align: center;
      margin-bottom:80px;
      color: #FFF;
  
    }

 
    
  }
  
  @media (max-width: 575px) and (min-width: 481px) {
    .home{
   
      background-size: cover;
      background-position: center;
       }
    .app {
      background-image: url('../../assets/images/LandingPage/planet2.png');
      background-repeat: no-repeat;
      background-size: 130% 100%;  
      background-position: center bottom   ; 
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 50px 0 50px 0; 
  
    }
    .app h1 {
      color: #EEE;
  font-size: 25px;
  max-width: 80%;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 40px 0;
  margin: auto;
    }
    .storeButtons{
      width: 95%;
      flex-direction: column; 
      margin-top: 2rem;
      gap: 20px;
    }
  .storeButton{

   
    width: 178px !important;
   
    }

    .contnet h1 {
      font-size: 35px;
      line-height: 30px;
    }
  
    .contnet p {
      max-width:  60%;
      font-size: 18px;
      line-height: 18px;
      margin-top: 1rem;
    }

  

   
   .services{
    margin: 20px 0 ;
   }
     .serviceCol{
      margin: 20px;
    }
    .downloadSection{
      margin-top: 0;
   
    }
    .dwonloadBTN{
      margin-top: 10px;
    }
    .services h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 75px;
      margin: auto;
      text-align: center;
      color: #FFF;
    }
    .services p{
      width: 80%;
     max-width: 100%;
    }
  
  }
  

  


  @media (min-width: 290px) and (max-width: 480px) {
  .home{
   
 background-size: cover;
 background-position: center ;
 height: 100vh;
  }
  .popupParagraph{
    padding: 1rem;
  }
  
 .dwonloadBTN {
    margin-top: 0;
  }
 
  .Containe{
   display: flex;
  text-align: start;
   align-items: center;
   justify-content: start !important;
  margin-top: 25%;
   
  }
  .dwonloadBTN{
    width: 200px;
  height: 54px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;

  padding: 15px 34px;
  }

 
    .contnet h1 {
    
      text-align: left;
      color:  #FFF;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: normal;

width: 315px;
    }
  
    .contnet p {
  
      color: #EEE;

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      margin-bottom: 20px;
   width: 100%;

    }

    .services{
      margin: 30px 0 1px 0;
        }
        .serviceCol{
      margin: 0;
        }


        

    .services h5{
      color: #EEE;
      text-align: left;
      margin-left: 7px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
    }
    .services p{
      color: #EEE;
      text-align: left;
      margin-left: 7px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
    }
    .IconBody{
   width: 90px;
   height: 90px;
   margin-left: 7px;
    }
.downloadSection{
  margin-top: 0;
  margin-bottom: 40px;
  
}

.downloadContent p{
text-align: left;
margin: 0 0 20px 2px;
margin-top: 40px;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;

}

.downloadSection .dwonloadBTN{
  margin-top: 80px;
}


   



  .app {
    background-image: url('../../assets/images/LandingPage/homePlante.png');
    background-repeat: no-repeat;
    background-size: 150% 110%; 
    background-position: 100% -199% ;

 

    height: 500px;
    position: relative;

    padding-top: 0px;
    margin-top: -50px;
  }
  



  
.appContent{
 width: 90%;
 max-width: 100%;
position: absolute;
bottom: 40px;
}

  .app h1{
margin-top: 0;
    color: #EEE;
text-align: left;
font-size: 35px;
font-style: normal;
font-weight: 700;
line-height: normal;
max-width: 80%;
  }
  .storeButtons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    gap: 10px; 
    margin-top: -25px;
  }

  .storeButton {
    width: auto !important; 
    height: 60px !important; 
  }


 

.services h1 {

font-size: 35px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 20px;
text-align: left;
margin-left: 7px;
max-width: 70%;
}
   
.whyChooseTitle {
  margin: auto;
  text-align: center;
  position: relative;
}

.whyChooseText {
  font-size: 60px;
}







.allRtl {
  text-align: right !important;
direction: rtl  !important;

}


.allRtl .contnet *{
  text-align: right;
}
.allRtl .contnet h1{
width: 100%;
}

.allRtl  .dwonloadBTN {

  text-align: center !important;
margin: 0; 
font-size: 25px;
padding: 10px 30px 0 0 ;
} 
.allRtl  .serviceCol {
  margin-right: 15px;
}
.allRtl.services  h1 {
  max-width: 85%;
  direction: rtl;
  text-align: right;
  margin-right: -20px;  
position: relative;
  
}
.allRtl.services  h5 {
font-size: 25px;
  
}
.allRtl.services  p{
  font-size: 20px;
    
  }
  

.allRtl.services .vectorImage{

margin-top: -10px;
right: -150px;
  transform: rotateY(200deg);
}
  

.allRtl .serviceCol *{
margin-right: 1px;

  text-align: right !important;

}
.allRtl .downloadContent p{
  margin-right: 1px;
font-size: 23px;
  text-align: right !important;
  margin-bottom: 30px;
}

.allRtl.downloadSection .dwonloadBTN{
  margin-top: 40px;
}
.allRtl .serviceCol .IconBody img{
margin: auto;
  
  }
  
  .allRtl.app .appHeading{
    direction: ltr;
    max-width: 100%;
    width: 100%;
    font-size: 30px;
  }


  }


  @media (min-width: 290px) and (max-width: 440px) {
    .vectorImage {
      display: block; 
      position: relative; 
      top: auto;
      
      transform: none;
      width: 50%;
      margin: 0 140px 0  0 ;
      
    
    }
  
  }