.loaderContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

 

.dark{
  background-color: #2B2A29;
}

.light {
  background-color: #EEE;
}
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .car__body {
    animation: shake 0.2s ease-in-out infinite alternate;
  }
  
  .car__line {
    transform-origin: center right;
    stroke-dasharray: 22;
    animation: line 0.8s ease-in-out infinite;
    animation-fill-mode: both;
  }
  
  .car__line--top {
    animation-delay: 0s;
  }
  
  .car__line--middle {
    animation-delay: 0.2s;
  }
  
  .car__line--bottom {
    animation-delay: 0.4s;
  }
  
  .car__wheel--left,
  .car__wheel--right {
    animation: shake 0.2s ease-in-out infinite alternate;
  }
  
  @keyframes shake {
    0% {
      transform: translateY(-1%);
    }
    100% {
      transform: translateY(3%);
    }
  }
  
  @keyframes line {
    0% {
      stroke-dashoffset: 22;
    }
    25% {
      stroke-dashoffset: 22;
    }
    50% {
      stroke-dashoffset: 0;
    }
    51% {
      stroke-dashoffset: 0;
    }
    80% {
      stroke-dashoffset: -22;
    }
    100% {
      stroke-dashoffset: -22;
    }
  }
  
  .riders {
    text-align: center;
    font-size: 24px;
    color: #BF00C2;
    letter-spacing: 5px;
    margin-top: 20px;
font-weight: 700;
    margin-left: 2rem;

  }
  