







 
 .navbar {
  position: fixed;
   width: 100%;
   height: 100px;
   z-index: 9999;
 }
 
 .dark.navbar {
   background-color: #2B2A29 !important; 
   box-shadow: none !important;
   padding: 24px 90px !important;
 
 }
 .light.navbar{
  padding: 24px 90px !important;

   background-color: #EEE !important; 

   box-shadow: none !important;
 
 }
 
 .navbar-brand {
   width: 149.45px;
   height: 37.74px;
   cursor: pointer;
 }
 
 
 
 .navbar-item {
   margin-left: 40px;
 }
 
 
 
 
 .navbar-link {
   font-size: 20px;
   font-weight: 600;
   line-height: 30px;
   text-align: left;
   text-decoration: none;
 }
 
 
 .dark .navbar-link {
   color: #EEEEEE !important;
 }
 
 .light .navbar-link{
   color: #2B2A29 !important;
 }
 .dark .barIcon{
   color: #EEEEEE;
 }
 .light .barIcon{
   color: #2B2A29;
 }
 
 
 
 .dark .navbar-link:hover {
   color: #BF00C2 !important;
 }
 

 .dark .navbar-link.active {
   color: #BF00C2 !important;
 }
 
 .light .navbar-link:hover {
   color: #BF00C2 !important;
 }
 
 
 .light .navbar-link.active {
   color: #BF00C2 !important;
 }
 
 
 .dropdown-toggle::after {
  display: none;
 }
 

 .language-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #EEE;
display: flex;
align-items: center;
justify-content: center;
font-size: 17px;
font-weight: 600;
  padding: 5px !important;
  margin: auto !important;
  background-color: #BF00C2 !important;
  box-shadow: none !important;
  border: none !important;

}
.language-button:hover {
  box-shadow: none !important;
}

.language-button:focus {
  box-shadow: none !important;
}




.navbar-collapse {

  transform: none;
  transition: none;
}


.arabic {
  direction: rtl;
}

.arabic .navbar-item {
  margin-left: 40px;
}
.navbar-collapse {
  display: none;
}

.navbar-collapse.show {
  display: flex !important;
}

.open {
  display: flex !important;
}

 
 @media (max-width: 1399px) {
   .dark.navbar {
 
     background-color: #2B2A29 !important; 
   }
   .light.navbar{
  
     background-color: #EEE !important; 
 
   }
 
   .dark .barIcon{
     color: #EEEEEE;
   }
   .light .barIcon{
     color: #EEEEEE;
   }
    .dark .navbar-link {
     color: #EEEEEE !important;
   }
   
   .light .navbar-link{
     color: #2B2A29 !important;
   }
  
    .navbar-item{
 
     margin-left: 2rem;
    
   }
 
 }
 
 @media (max-width: 1199px) {
   .dark.navbar {
 
     background-color: #2B2A29 !important; 
   }
   .light.navbar{
  
     background-color: #EEE !important; 
 
   }
 
   .dark .barIcon{
     color: #EEEEEE;
   }
   .light .barIcon{
     color: #EEEEEE;
   }
    .dark .navbar-link {
     color: #EEEEEE !important;
   }
   
   .light .navbar-link{
     color: #2B2A29 !important;
   }
 
 }
 
 @media (max-width: 989px) {
 
  
   .dark.navbar {
     width: 100% !important;
     max-width: 100% !important;
     padding: 1rem 1rem !important;
     margin: 0;
     background-color: #2B2A29 !important; 
   }
   .light.navbar{
     width: 100% !important;
     max-width: 100% !important;
     padding: 1rem 1rem !important;
     margin: 0;
     background-color: #EEE !important; 
 
   }
 
   .navbar-brand {
     width: 149.45px;
     height: 37.74px;
   }
   .dark .barIcon{
     color: #EEEEEE;
   }
   .light .barIcon{
     color: #2B2A29;
   }
   .navbar-item {
     margin-top: 2rem !important;
     margin-left: 0;
   }
   .dark .navbar-link {
     color: #EEEEEE !important;
   }
   
   .light .navbar-link{
     color: #2B2A29 !important;
   }
   .navbar-buttons{
    width: 100px;
    display: flex !important;
    justify-content: space-around !important;
    margin: 1rem !important;
   }

 


 .open {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2B2A29; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    z-index: 999;
    transform: none !important;
    transition: none  !important;
  }
  
  .light .open {
    background-color: #EEEEEE; 
  }
  

.open .nav {
    flex-direction: column;
    align-items: center;
  }
  
.open .navbar-item {
    margin: 1rem 0;
  }
  
.open .language-button {
    margin: 1rem 0;
  }
  
.open .ui-switch {
    margin: 1rem 0;
  }

  .arabic .navbar-item {
    margin-left: 0px;
  }
  
 }
 
 @media (max-width: 475px) {
 
 
   
   .dark.navbar {
 
     padding: .5rem !important;
     margin: 0;
     background-color: #2B2A29 !important; 
   }
   .light.navbar{
  
     padding: .5rem !important;
     margin: 0;
     background-color: #EEE !important; 
 
   }
  
   .dark .navbar-link {
     color: #EEEEEE !important;
   }
   
   .light .navbar-link{
     color: #2B2A29 !important;
   }
   .dark .barIcon{
     color: #EEEEEE;
   }
   .light .barIcon{
     color: #2B2A29;
   }
   .navbar-brand {
     width: 149.45px;
     height: 37.74px;
   }
 

   .navbar-buttons{
    width: 100px;
    display: flex !important;
    justify-content: space-around !important;
    margin: 1rem !important;
   }

 


 .open {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2B2A29;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    z-index: 999;
    transform: none !important;
    transition: none  !important;
  }
  
  .light .open {
    background-color: #EEEEEE; 
  }
  
  /* Centering nav items */
.open .nav {
    flex-direction: column;
    align-items: center;
  }
  
.open .navbar-item {
    margin: 1rem 0;
  }
  
.open .language-button {
    margin: 1rem 0;
  }
  
.open .ui-switch {
    margin: 1rem 0;
  }



  .arabic .navbar-item {
    margin-left: 0px;
  }

 }
 
 


 


.ui-switch {

 
  --circle-diameter: 30px;
  --circle-bg: #BF00C2;

  display: flex;

margin: 0 !important;
}


.ui-switch input {

  display: none;

}

.slider {
  width: var(--switch-width);
  height: var(--switch-height);
  background: transparent;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.slider .circle {
 

  width: var(--circle-diameter);
  height: var(--circle-diameter);

  background: var(--circle-bg);
  border-radius: inherit;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTQuMiAyLjVsLS43IDEuOC0xLjguNyAxLjguNy43IDEuOC42LTEuOEw2LjcgNWwtMS45LS43LS42LTEuOHptMTUgOC4zYTYuNyA2LjcgMCAxMS02LjYtNi42IDUuOCA1LjggMCAwMDYuNiA2LjZ6IiAvPgo8L3N2Zz4=");

  background-repeat: no-repeat;
  background-position: center center;
  
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  ;
}

.slider .circle::before {


  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  border-radius: inherit;
  transition: all 500ms;
  opacity: 0;
}


.ui-switch input:checked + .slider .circle {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTkuMzA1IDEuNjY3VjMuNzVoMS4zODlWMS42NjdoLTEuMzl6bS00LjcwNyAxLjk1bC0uOTgyLjk4Mkw1LjA5IDYuMDcybC45ODItLjk4Mi0xLjQ3My0xLjQ3M3ptMTAuODAyIDBMMTMuOTI3IDUuMDlsLjk4Mi45ODIgMS40NzMtMS40NzMtLjk4Mi0uOTgyek0xMCA1LjEzOWE0Ljg3MiA0Ljg3MiAwIDAwLTQuODYyIDQuODZBNC44NzIgNC44NzIgMCAwMDEwIDE0Ljg2MiA0Ljg3MiA0Ljg3MiAwIDAwMTQuODYgMTAgNC44NzIgNC44NzIgMCAwMDEwIDUuMTM5em0wIDEuMzg5QTMuNDYyIDMuNDYyIDAgMDExMy40NzEgMTBhMy40NjIgMy40NjIgMCAwMS0zLjQ3MyAzLjQ3MkEzLjQ2MiAzLjQ2MiAwIDAxNi41MjcgMTAgMy40NjIgMy40NjIgMCAwMTEwIDYuNTI4ek0xLjY2NSA5LjMwNXYxLjM5aDIuMDgzdi0xLjM5SDEuNjY2em0xNC41ODMgMHYxLjM5aDIuMDg0di0xLjM5aC0yLjA4NHpNNS4wOSAxMy45MjhMMy42MTYgMTUuNGwuOTgyLjk4MiAxLjQ3My0xLjQ3My0uOTgyLS45ODJ6bTkuODIgMGwtLjk4Mi45ODIgMS40NzMgMS40NzMuOTgyLS45ODItMS40NzMtMS40NzN6TTkuMzA1IDE2LjI1djIuMDgzaDEuMzg5VjE2LjI1aC0xLjM5eiIgLz4KPC9zdmc+");

}

.ui-switch input:active + .slider .circle::before {
  transition: 0s;
  opacity: 1;
  width: 0;
  height: 0;
}






